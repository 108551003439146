<template>
  <div>
    <div class="pbg">
      <div v-if="currentwidth < 720">
        <div class="padd20">
          <new-header :size="'mini'" :name="'partners'"></new-header>
        </div>
      </div>
      <div v-else>
        <div class="w70 padd20">
          <new-header :name="'partners'"></new-header>
        </div>
      </div>
      <div class="mt60 mcenter colw" style="margin-top: 100px">
        <div :class="[currentwidth<720?'font36':'font50']">助力企业，共创未来</div>
        <div class="mt30" :class="[currentwidth<720?'font18':'font30']">— — 精细打造，精品服务，精诚合作 — —</div>
      </div>
    </div>
    <div class="mt30 mcenter">
      <div class="font20">来自全国各地客户的肯定与信任</div>
      <div class="mt10 col6 font15 mb10">服务的企业客户超百家</div>
      <div class="mcenter" :class="[currentwidth > 1000?'w60':(currentwidth > 400?'w70':'w90')]">
        <div class="zbtable">
          <div class="zbtr mcenter" v-for="(it,key) in partners" :key="key">
            <div class="zbcell zbleft" v-for="(ik,kk) in it" :key="kk">{{ik}}</div>
          </div>
        </div>
      </div>
      <div class="mt10 col6 font16">其他未列举...</div>
    </div>
    <div>
      <new-bottom :name="'partners'"></new-bottom>
    </div>
  </div>
</template>

<script>
import newHeader from "@/components/newHeader";
import newBottom from "@/components/newBottom";
export default {
  name: "partners",
  components: {newHeader,newBottom},
  data(){
    return{
      partners: [
        ["中交第四航务工程局","中交广航局第五工程","中铁建工集团",],
        ["中邦生态环境有限公司","贵州建工集团第六建筑工程","中铁二局川渝指挥部"],
        ["中国华西工程设计建设有限公司","陕西路桥集团第二工程", "浙江省一建建设集团"],
        ["宁夏交通建设股份有限公司","四川蜀元建筑集团","中九华南工程技术有限公司"],
        ["四川中鑫建业建设工程有限公司","广州市第二市政工程有限公司","四川春航建设集团"]
      ],
      currentwidth: 1000,
    }
  },
  mounted() {
    let that = this;
    that.currentwidth = window.document.body.offsetWidth;
    window.onresize = () => {
      that.currentwidth = window.document.body.offsetWidth;
    }
  },
  methods: {

  },
}
</script>

<style scoped>
.pbg{background-image: url("../../assets/img/hztop.png");background-repeat: no-repeat;height: 460px;width: 100vw;}
.zbtable {display: table;width: 100%;table-layout: fixed;word-wrap: break-word;border-collapse: collapse;}
.zbtable .zbtr {display: table-row;}
.zbtable .zbcell {display: table-cell;border: 1px solid #D1D1D1;padding: 20px 0;}
.zbtable .zbleft {width: 33%}
.w60{width: 60vw;margin: auto;}
.w90{width: 90vw;margin: auto;}
</style>